/* TODO: [Clean Jwts Auth] */
export const setSvcUsersFeatureFlags = featureFlags => {
  const featureDevSvcUsers = featureFlags.feature_flags_dev.find(
    flag => flag.name === 'FEATUREDEV_SVC_USERS',
  );

  const featureDevSvcUsersForQa = featureFlags.feature_flags_dev.find(
    flag => flag.name === 'FEATUREDEV_SVC_USERS_QA',
  );

  window.featureFlag = {
    FEATUREDEV_SVC_USERS: featureDevSvcUsers?.active,
    FEATUREDEV_SVC_USERS_QA: featureDevSvcUsersForQa?.active,
  };
};
