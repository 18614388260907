<template>
  <div :class="inputWrapperClasses">
    <div
      v-if="showTopPlaceholder"
      class="st-input__top-placeholder"
    >
      {{ formattedPlaceholder }}
    </div>
    <input
      v-model="localValue"
      :type="currentInputType"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      :step="step"
      :disabled="disabled"
      :tabindex="tabindex"
      class="st-input"
      @blur="handleBlur"
      @focus="handleFocus"
    >
    <div
      v-if="type === 'password'"
      class="st-input__eye-wrapper"
      @click="togglePasswordDisplay"
    >
      <OpenEye v-if="currentInputType === 'password'" />
      <ClosedEye v-else />
    </div>
  </div>
</template>

<script>
import {
  OpenEye,
  ClosedEye,
} from '../../icons';

const TYPES = [
  'text',
  'password',
  'email',
  'number',
  'search',
];

export default {
  name: 'Input',
  components: { OpenEye, ClosedEye },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: type => TYPES.includes(type),
    },
    autocomplete: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    min: {
      type: [String, Number],
      default: null,
    },
    max: {
      type: [String, Number],
      default: null,
    },
    step: {
      type: [String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errored: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showTopPlaceholder: false,
      localValue: this.value,
      currentInputType: this.type,
    };
  },
  computed: {
    inputWrapperClasses() {
      return {
        'st-input__wrapper': true,
        'st-input__wrapper--focused': this.showTopPlaceholder,
        'st-input__wrapper--errored': this.errored && !this.showTopPlaceholder,
      };
    },
    formattedPlaceholder() {
      return this.required ? `${this.placeholder}*` : this.placeholder;
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    handleFocus() {
      this.showTopPlaceholder = true;
    },
    handleBlur() {
      this.showTopPlaceholder = false;
    },
    handleInput(value) {
      this.$emit('input', value);
    },
    togglePasswordDisplay() {
      if (this.currentInputType === 'text') {
        this.currentInputType = 'password';
      } else {
        this.currentInputType = 'text';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.st-input__wrapper {
  border: 1px solid $sk-grey-10;
  margin: 12px 0;
  width: 100%;
  border-radius: 4px;
  background: white;
  color: black;
  position: relative;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 50px;

  .st-input__top-placeholder {
    position: absolute;
    top: -7px;
    left: 10px;
    font-size: 11px;
    background: white;
    padding: 0 3px;
  }
}

.st-input__wrapper--focused {
  border: 2px solid $sk-blue;
  padding: 4px 9px;
}

.st-input__wrapper--errored {
  border: 2px solid $sk-error;
  padding: 4px 9px;
}

.st-input__eye-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  width: 10%;
  height: 30px;
}

.st-input {
  width: 90%;
  border: 0;
  outline: 0;
  background-color: white;
  color: black;
  font-size: $fs-text-m;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding-top: 3px;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: $sk-grey-50;
  }
}
</style>
